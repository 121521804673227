import React, { useEffect, createContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import RadioPlayer from "../../components/RadioPlayer";
import Skeleton from '@mui/material/Skeleton';

// Create context for sharing state across components
const MyContext = createContext();

const PublicLayout = ({ children, ...rest }) => {

  // State variables
  const [audioFiles, setAudeioFiles] = useState([]);
  const [radioView, setRadioView] = useState(null);
  const [isOpenRadio, setisOpenRadio] = useState(false);
  const [playSongIndex, setplaySongIndex] = useState(0);
  const [isLoadingRadioPlayerLoading, setisLoadingRadioPlayerLoading] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [headerEle, setHeaderEle] = useState();

  // Get current location using useLocation hook from react-router-dom
  const location = useLocation();

  // Check if the page is being viewed in a specific medium (e.g., android, ios, etc.)
  const urlParams = new URLSearchParams(window.location.search);
  const medium = urlParams.get('medium');
  let isMedium = medium && medium !== undefined && medium !== null && medium !== "" && (medium === "android" || medium === "tizen" || medium === "roku" || medium === "ios" || medium === "webos") ? true : false

  // Context value to share state across components
  const value = {
    setAudeioFiles,
    audioFiles,
    setisOpenRadio,
    isOpenRadio,
    setplaySongIndex,
    playSongIndex,
    setRadioView,
    radioView,
    setisLoadingRadioPlayerLoading,
    isLoadingRadioPlayerLoading,
    setHeaderEle,
    headerEle,
    setFullscreen,
    fullscreen,
    isPopup,
    setIsPopup
  };
  // Effect to run on location change
  useEffect(() => {
    window.scrollTo(0, 0);

    // Set isMedium based on medium parameter in URL
    if ((medium !== undefined && medium !== null && medium !== "") && (medium === "ios" || medium === "android" || medium === "tizen" || medium === "roku" || medium === "webos")) {
      isMedium = true
    }
    else {
      isMedium = false
    }

    // Determine radio view based on current location
    let keyword = location.pathname.indexOf("podcast")
    let redioViewUrlTrack = location.pathname.indexOf("audio")
    // alert(redioView)
    // console.log('keyword', keyword)
    // debugger
    if (keyword === -1) {
      setRadioView('minimize');
      if (isOpenRadio === true) {
        document.body.classList.add('radioOpen')
      }
    }
    else if (redioViewUrlTrack === 7) {
      document.body.classList.add('radioOpen')
    }
    else {
      setRadioView('minimize');
      if (isOpenRadio === false) {
        document.body.classList.remove('radioOpen')
      }
    }
  }, [location]);

  // console.log('location', location)
  return (
    <>
      <MyContext.Provider value={value}>
        {/* Render header if not in a specific medium */}
        {!isMedium && <Header isMedium={isMedium} locationdata={location.pathname} />}
        <main className="main">{children}</main>
        {/* Render footer if not in a specific medium */}
        {!isMedium && <Footer isMedium={isMedium} />}

        {/* Render radio player skeleton loader */}
        {isLoadingRadioPlayerLoading === true &&
          <div className={`radiopPlayer skeletonLoader`}>
            <div className='audioWrapper d-flex align-items-center flex-column'>
              <Skeleton className="box1 d-flex align-items-center w-100">
                <Skeleton className="box2" />
                <div className="w-25" style={{ visibility: 'inherit' }}>
                  <Skeleton className="box3" />
                  <Skeleton className="box3 mt-2" style={{ width: '60px' }} />
                </div>
                <div className="box4Wrapper d-flex flex-column">
                  <Skeleton className="circle" variant="circular" />
                  <Skeleton className="box4" />
                </div>
              </Skeleton>
            </div>
          </div>
        }

        {/* Render radio player if isOpenRadio is true */}
        {/* {console.log('radioView', radioView)} */}
        {isOpenRadio === true && radioView && <RadioPlayer view={radioView} />}
      </MyContext.Provider>
    </>
  );
};

export default PublicLayout;
export { MyContext }
